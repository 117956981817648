import { Routes } from '@angular/router';

const mainTitle = 'Blog TA';


export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./home/home.page').then((m) => m.HomePage),data: { title: mainTitle },
  },
  {
    path: 'post/:id',
    loadComponent: () =>
      import('./templates/view-post/view-post.page').then((m) => m.ViewPostPage),data: { title: mainTitle },
  },
  {
    path: 'plaja/:id',
    loadComponent: () =>
      import('./templates/view-beach/view-beach.page').then((m) => m.ViewBeachPage),data: { title: mainTitle },
  },
  {
    path: '**',
    redirectTo: '',
  },
];

