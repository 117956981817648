export const environment = {
  production: true,

  firebaseConfig: {
    apiKey: "AIzaSyCE03tdo06iJboqAo0mQI-kR1Da9QToLPs",
    authDomain: "tourist-advisor-blog.firebaseapp.com",
    projectId: "tourist-advisor-blog",
    storageBucket: "tourist-advisor-blog.appspot.com",
    messagingSenderId: "406311233379",
    appId: "1:406311233379:web:427c5bdc7d71b363a6d556"
  }

};
